<template>
  <v-container>
    <v-row cols="12" class="ma-5">
      <v-col class="ma-2">
        <v-row justify="center">
          <v-card :loading="loading" class="mx-auto" max-width="374">
            <template slot="progress">
              <v-progress-linear
                color="deep-purple"
                height="10"
                indeterminate
              ></v-progress-linear>
            </template>

            <v-card-text>
              <v-date-picker
                v-model="fecha"
                locale="es-cn"
                first-day-of-week="1"
                full-width
              >
              </v-date-picker>
            </v-card-text>

            <v-divider class="mx-4"></v-divider>
            <v-card-title>Categorias</v-card-title>

            <v-card-text>
              <v-chip-group
                v-model="opcionCategoria"
                active-class="primary white--text"
                mandatory
                column
              >
                <div
                  v-for="categoria in categorias"
                  :key="categoria.id"
                  :value="categoria.id"
                >
                  <v-chip :value="categoria.id">
                    {{ categoria.titulo }}
                  </v-chip>
                </div>
              </v-chip-group>
            </v-card-text>

            <v-divider class="mx-4"></v-divider>

            <v-card-title>Tipo de Servicio</v-card-title>

            <v-card-text>
              <v-chip-group
                v-model="opcionServicio"
                active-class="primary white--text"
                mandatory
                column
              >
                <div
                  v-for="servicio in servicios"
                  :key="servicio.id"
                  :value="servicio"
                >
                  <div
                    v-for="servicioCategoria in serviciosCategorias"
                    :key="servicioCategoria.id"
                    :value="servicioCategoria"
                  >
                    <div
                      v-if="
                        servicioCategoria.idServicio == servicio.id &&
                        servicioCategoria.idCategoria == opcionCategoria
                      "
                    >
                      <v-chip :value="servicio">
                        {{ servicio.titulo }}
                      </v-chip>
                    </div>
                  </div>
                </div>
              </v-chip-group>
            </v-card-text>

            <v-divider class="mx-4"></v-divider>

            <v-card-title>Horarios disponibles</v-card-title>

            <v-card-text>
              <v-chip-group
                v-model="opcionHorario"
                active-class="primary white--text"
                mandatory
                column
              >
                <v-chip
                  v-for="horario in horarios"
                  :key="horario.id"
                  :value="horario"
                >
                  {{ horario.inicio }}
                </v-chip>
              </v-chip-group>
            </v-card-text>
            <v-divider class="mx-4"></v-divider>

            <v-card-title>Ingrese sus datos</v-card-title>

            <v-card-text>
              <v-form v-model="valid">
                <v-container>
                  <v-text-field
                    v-model="nombre"
                    :rules="nameRules"
                    label="Nombre"
                    required
                  ></v-text-field>

                  <v-text-field
                    v-model="email"
                    :rules="emailRules"
                    label="E-mail"
                    required
                  ></v-text-field>

                  <v-text-field
                    v-model="phone"
                    :rules="phoneRules"
                    label="Telefono"
                    required
                  ></v-text-field>
                </v-container>
              </v-form>
            </v-card-text>
            <v-divider class="mx-4"></v-divider>

            <v-card-title>Resumen</v-card-title>

            <v-card-text>
              <div class="mb-2">
                <div class="font-weight-black">{{ opcionServicio.titulo }}</div>
             
              <div>{{ opcionServicio.descripcion }}</div>

              </div>
              
              <v-divider></v-divider>
             
              <v-list>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon> mdi-cash-multiple </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>
                      $ {{ opcionServicio.precio }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon> mdi-calendar </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>
                      {{ fecha }} - {{ opcionHorario.inicio }} -
                {{ opcionHorario.termino }}
                    </v-list-item-title>

                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon> mdi-account </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ nombre }}</v-list-item-title>

                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon> mdi-phone </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ phone }}</v-list-item-title>

                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon> mdi-email </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ email }}</v-list-item-title>

                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>

            <v-card-actions>
              <v-btn color="primary" text @click="postReserva" block>
                Reservar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    serviciosCategorias: [],
    horarios: [],
    categorias: [],
    servicios: [],
    opcionCategoria: null,
    opcionServicio: [],
    opcionHorario: [],
    reservas: [],
    fecha: new Date(Date.now())
      .toISOString()
      .substr(0, 10),
    loading: false,
    servicios: [
      {
        hora: "Frozen Yogurt",
        calories: 159,
        fat: 6.0,
      },
    ],
    valid: false,
    nombre: "",
    phone: "",
    nameRules: [(v) => !!v || "Nombre es requerido"],
    phoneRules: [(v) => !!v || "Telefono es requerido"],
    email: "",
    emailRules: [
      (v) => !!v || "Correo es requerido",
      (v) => /.+@.+/.test(v) || "Correo no valido",
    ],
  }),
  created() {
    this.getCategorias();
    this.getServicios();
    this.getServiciosCategorias();
    this.getHorarios();
  },
  methods: {
    reserve() {
      this.loading = true;

      setTimeout(() => (this.loading = false), 2000);
    },
    async getReservas() {
      try {
        const response = await this.axios.get("/reserva");
        this.reservas = response.data;
        console.log(this.reservas);
      } catch (error) {
        console.log(error);
      }
    },

    async postReserva() {
      try {
        console.log(this.fecha);
        const response = await this.axios.post("/reserva", {
          nombre: this.nombre,
          telefono: this.phone,
          correo: this.email,
          
          fecha: this.fecha,
          idCategoria: this.opcionCategoria,
          idHorario: this.opcionHorario.id,
          idHervicio: this.opcionServicio.id,

          
        });
        this.reservas = response.data;
        console.log(this.reservas);
      } catch (error) {
        console.log(error);
      }
    },
    

    async getCategorias() {
      try {
        const response = await this.axios.get("/categoria");
        this.categorias = response.data;
        console.log(this.categorias);
      } catch (error) {
        console.log(error);
      }
    },
    async getServicios() {
      try {
        const response = await this.axios.get("/servicio");
        this.servicios = response.data;
        console.log(this.servicios);
      } catch (error) {
        console.log(error);
      }
    },
    async getServiciosCategorias() {
      try {
        const response = await this.axios.get("/serviciocategoria");
        this.serviciosCategorias = response.data;
        console.log(this.serviciosCategorias);
      } catch (error) {
        console.log(error);
      }
    },
    async getHorarios() {
      try {
        const response = await this.axios.get("/horario");
        this.horarios = response.data;
        console.log(this.horarios);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
